








































































































































































import { promiseHelper } from '@/helpers/promise-helper'
import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { MarketItemDetailViewModel } from '../viewmodels/market-item-detail-viewmodel'
@Observer
@Component({
  components: {
    'hover-image': () => import('./hover-image.vue'),
  },
})
export default class MarketBuyDialog extends Vue {
  @Inject() vm!: MarketItemDetailViewModel
  dialog = false
  resolve?: (any) => void

  async open() {
    this.vm.resetOfferInput()
    this.dialog = true
    await promiseHelper.delay(100)
    return new Promise((r) => (this.resolve = r))
  }
  async cancel(success = false) {
    this.resolve && this.resolve(success)
    this.dialog = false
    await promiseHelper.delay(100)
  }

  handleMakeOffer() {
    if (this.vm.checkOfferInput()) this.cancel(true)
  }
}
